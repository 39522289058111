<template>
	<div class="container">
		<div class="w1200">
			<iframe :src="url">

			</iframe>
		</div>

	</div>
</template>

<script>
	import urlobj from '@/api/baseurl.js'
	export default {
		data() {
			return {
				url: '',
				list: [],
				index: '',
				
			}
		},
		created() {
			
		},
		watch: {
			"$store.state.jqIndex": {
				handler(newVal,oldVal) {
					this.index = newVal - 1
					this.getLink()
					
					
				},
				deep: true,
				immediate: true,
		

			}
		},
		methods: {
			getLink(){
				this.$http({
						url: `/configApi/scenicSpotGuide`,
						method: "POST",
					}).then((res) => {
						if(res.state == 10200){
							this.list = res.data
							this.url = this.list[this.index].contents
						}
						
					});
			}
		}
	}
</script>

<style lang="scss" scoped>
.container{
	background: #5B2528;
	.el-carousel,::v-deep .el-carousel__container{
		height: 800px;
	}
	iframe{
		height: 800px;
		width: 100%;
		display: block;
	}
	img{
		height: 800px;
		width: 100%;
	}
	
}
	
</style>
